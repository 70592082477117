import React from "react";

let About = () => {
    return (
        <>
            <section className="bg-blue-200 py-20">
                <div className="container mx-auto px-6">
                    <div className="flex flex-col lg:flex-row">
                        <div className="lg:w-1/2">
                            <h2 className="text-4xl font-bold text-gray-800 mb-4">Tentang Kita</h2>
                            <p className="text-gray-700 mb-8">
                                Misi kami adalah menghubungkan individu berbakat dengan kesempatan kerja yang tepat
                                <br/>
                                Visi kami adalah menjadi sumber daya utama untuk menciptakan hubungan yang kuat antara pencari kerja dan perusahaan.
                            </p>
                            <p className="text-gray-700 mb-8">
                                Kami berkomitmen untuk memberikan pengalaman pencarian kerja yang efisien dan bermanfaat bagi semua pihak yang terlibat.
                            </p>
                        </div>
                        <div className="lg:w-1/2 lg:pl-12 mt-12 lg:mt-0 max-w-xl">
                            <img
                                className="w-full h-auto object-cover"
                                src="https://img.freepik.com/free-vector/business-leaders-shaking-hands_74855-4376.jpg"
                                alt="About"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About