import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../contexts/globalContext";
import Card from "../components/card";
import { Link } from "react-router-dom";
import Search from "../components/search";

let Beranda = () => {
    const { state, handleFunction } = useContext(GlobalContext)

    const {
        data,
        fetchStatus,
        search, setSearch
    } = state

    const {
        fetchData,
    } = handleFunction

    useEffect(() => {
        if (fetchStatus === true) {
            fetchData()
        }

    }, [fetchData, fetchStatus])

    return (
        <>
            <header className="bg-blue-200 ">
                <div className="container mx-auto px-6 py-20">
                    <h1 className="text-4xl font-bold text-center text-gray-800">
                        Website Lowongan Pekerjaan
                    </h1>
                    <p className="mt-2 text-xl text-center text-gray-600">
                        Cari Pekerjaan yang Tepat Untuk Anda Di sini
                    </p>
                    <div className="flex justify-center mt-4">
                        <Link to='/about' className="text-gray-800 font-bold py-2 px-4 border border-gray-900 rounded-lg">
                            Tentang Kita
                        </Link>
                    </div>
                    <div className="justify-center mt-4 mx-20">
                        <Search from={'beranda'} />
                    </div>
                </div>
            </header>
            {/* Job Listing Section */}
            <section className="bg-white py-8">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl font-bold text-center text-gray-800">
                        Job Vacancies
                    </h2>
                    {/* Job Card Component */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
                        {data !== null && data.map((res, index) => {
                            if (search == '') {
                                return (
                                    <Card
                                        id={res.id}
                                        title={res.title}
                                        job_description={res.job_description}
                                        job_qualification={res.job_qualification}
                                        job_type={res.job_type}
                                        job_tenure={res.job_tenure}
                                        job_status={res.job_status}
                                        company_name={res.company_name}
                                        company_image_url={res.company_image_url}
                                        company_city={res.company_city}
                                        salary_min={res.salary_min}
                                        salary_max={res.salary_max}
                                    />
                                )
                            } else {
                                if (res.title == search) {
                                    return (
                                        <Card
                                            id={res.id}
                                            title={res.title}
                                            job_description={res.job_description}
                                            job_qualification={res.job_qualification}
                                            job_type={res.job_type}
                                            job_tenure={res.job_tenure}
                                            job_status={res.job_status}
                                            company_name={res.company_name}
                                            company_image_url={res.company_image_url}
                                            company_city={res.company_city}
                                            salary_min={res.salary_min}
                                            salary_max={res.salary_max}
                                        />
                                    )
                                }
                            }
                        })}
                    </div>
                    {/* End of Job Card Component */}
                </div>
            </section>
        </>

    )
}

export default Beranda