import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../contexts/globalContext";

let Dashboard = () => {
    const { state, handleFunction } = useContext(GlobalContext)

    const {
        data,
        fetchStatus, setFetchStatus,
    } = state

    const {
        fetchData,
    } = handleFunction

    useEffect(() => {
        if (fetchStatus === true) {
            fetchData()
        }

    }, [fetchStatus, setFetchStatus, fetchData])

    return (
        <>
            <div className="p-4 sm:ml-64">
                <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14">
                    <div className="relative overflow-x-auto mb-10 font-bold text-lg text-left">
                        <h1>Dashboard Data</h1>
                    </div>
                    <div className=" gap-4 mb-4">
                        <div className="flex items-center justify-center h-24 rounded bg-blue-200 dark:bg-gray-800">
                            <p className="text-2xl text-black dark:text-gray-500">{data !== null && data.length} Lowongan Tersedia</p>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Dashboard