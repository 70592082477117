import React from "react";
import Footer from "../components/footer";
import CustomNavbar from "../components/navbar";

const Layout = (props) => {
    return (
        <>
            <CustomNavbar/>
            {props.children}
            <Footer/>
        </>
    )
}

export default Layout