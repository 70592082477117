import './App.css';
import Layout from './layouts/layout';
import Beranda from './pages/beranda';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import About from './pages/about';
import { GlobalProvider } from './contexts/globalContext';
import Dashboard from './pages/dashboard';
import DashboardLayout from './layouts/dashboard-layout';
import ListJobVacancy from './pages/list-job-vacancy';
import DataForm from './pages/form';
import Detail from './pages/detail';
import Login from './pages/login';
import Signin from './pages/signin';
import GantiPassword from './pages/ganti-password';

function App() {
  return (
    <div className="App">
      <>
        <BrowserRouter>
          <GlobalProvider>
            <Routes>
              <Route path='/' element={
                <Layout>
                  <Beranda />
                </Layout>
              } />
              <Route path='/about' element={
                <Layout>
                  <About />
                </Layout>
              } />
              <Route path='/detail/:idData' element={
                <Layout>
                  <Detail />
                </Layout>
              } />
              <Route path='/dashboard' element={
                <DashboardLayout>
                  <Dashboard />
                </DashboardLayout>
              } />
              <Route path='/dashboard/list-job-vacancy' element={
                <DashboardLayout>
                  <ListJobVacancy />
                </DashboardLayout>
              } />
              <Route path='/dashboard/list-job-vacancy/form' element={
                <DashboardLayout>
                  <DataForm />
                </DashboardLayout>
              } />
              <Route path='/dashboard/list-job-vacancy/edit/:variableIdData' element={
                <DashboardLayout>
                  <DataForm />
                </DashboardLayout>
              } />
              <Route path='/login' element={
                <Layout>
                  <Login />
                </Layout>
              } />
              <Route path='/signin' element={
                <Layout>
                  <Signin />
                </Layout>
              } />
              <Route path='/dashboard/ganti-password' element={
                <DashboardLayout>
                  <GantiPassword />
                </DashboardLayout>
              } />
            </Routes>
          </GlobalProvider>
        </BrowserRouter>
      </>
    </div>
  );
}

export default App;
