import React, { useState } from "react";
import { createContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const GlobalContext = createContext()

export const GlobalProvider = (props) => {
    const urlApi = 'https://dev-example.sanbercloud.com/api/job-vacancy'

    let [tokenApi, setTokenApi] = useState('eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvZGV2LWV4YW1wbGUuc2FuYmVyY2xvdWQuY29tXC9hcGlcL2xvZ2luIiwiaWF0IjoxNjg3NDkzNTE3LCJleHAiOjE2ODgwOTgzMTcsIm5iZiI6MTY4NzQ5MzUxNywianRpIjoiNDRRMjRFTVd2T2NPdGkyNiIsInN1YiI6MTM2MiwicHJ2IjoiODdlMGFmMWVmOWZkMTU4MTJmZGVjOTcxNTNhMTRlMGIwNDc1NDZhYSJ9.XBMSWxPvZq3C6q8Z869Bd7rgtRHdTnbmu9p0jUCVaQc')

    const [data, setData] = useState(null)

    let navigate = useNavigate()

    const [input, setInput] = useState({
        title: "",
        job_description: "",
        job_qualification: "",
        job_type: "",
        job_tenure: "",
        job_status: 0,
        company_name: "",
        company_image_url: "",
        company_city: "",
        salary_min: 0,
        salary_max: 0,
        name: "",
        image_url: "",
        email: "",
        password: "",
        current_password: "",
        new_password: "",
        new_confirm_password: ""
    })

    const [fetchStatus, setFetchStatus] = useState(true)

    let [currentId, setCurrentId] = useState(-1)

    let [search, setSearch] = useState("")

    const handleInput = (event) => {
        let name = event.target.name
        let value = event.target.value

        if (name === 'title') {
            setInput({ ...input, title: value })
        }
        if (name === 'job_description') {
            setInput({ ...input, job_description: value })
        }
        if (name === 'job_qualification') {
            setInput({ ...input, job_qualification: value })
        }
        if (name === 'job_type') {
            setInput({ ...input, job_type: value })
        }
        if (name === 'job_tenure') {
            setInput({ ...input, job_tenure: value })
        }
        if (name === 'job_status') {
            setInput({ ...input, job_status: value })
        }
        if (name === 'company_name') {
            setInput({ ...input, company_name: value })
        }
        if (name === 'company_image_url') {
            setInput({ ...input, company_image_url: value })
        }
        if (name === 'company_city') {
            setInput({ ...input, company_city: value })
        }
        if (name === 'salary_min') {
            setInput({ ...input, salary_min: value })
        }
        if (name === 'salary_max') {
            setInput({ ...input, salary_max: value })
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        let {
            title,
            job_description,
            job_qualification,
            job_type,
            job_tenure,
            job_status,
            company_name,
            company_image_url,
            company_city,
            salary_min,
            salary_max,
        } = input

        if (currentId === -1) {
            axios.post(urlApi, {
                title,
                job_description,
                job_qualification,
                job_type,
                job_tenure,
                job_status,
                company_name,
                company_image_url,
                company_city,
                salary_min,
                salary_max,
            },
                { headers: { "Authorization": "Bearer " + tokenApi } }
            )
                .then((res) => {
                    console.log(res)
                    setFetchStatus(true)
                }).catch((error) => {
                    console.log(error)
                })
        } else {
            axios.put(`${urlApi}/${currentId}`, {
                title,
                job_description,
                job_qualification,
                job_type,
                job_tenure,
                job_status,
                company_name,
                company_image_url,
                company_city,
                salary_min,
                salary_max,
            },
                { headers: { "Authorization": "Bearer " + tokenApi } }
            )
                .then((res) => {
                    setFetchStatus(true)
                }).catch((error) => {
                    console.log(error)
                })
        }

        setCurrentId(-1)

        setInput({
            title: "",
            job_description: "",
            job_qualification: "",
            job_type: "",
            job_tenure: "",
            job_status: 0,
            company_name: "",
            company_image_url: "",
            company_city: "",
            salary_min: 0,
            salary_max: 0,
        })
    }

    const handleDelete = (event) => {
        let idData = parseInt(event.target.value)

        axios.delete(`${urlApi}/${idData}`,
            { headers: { "Authorization": "Bearer " + tokenApi } }
        )
            .then((res) => {
                setFetchStatus(true)
            }).catch((error) => {
                console.log(error)
            })
    }

    const handleEdit = (event) => {
        let idData = parseInt(event.target.value)
        setCurrentId(idData)
        navigate(`/dashboard/list-job-vacancy/edit/${idData}`)
    }

    const fetchData = () => {
        axios.get(urlApi).then((res) => {
            setData([...res.data['data']])
        }).catch((error) => {
            console.log(error)
        })
        setFetchStatus(false)

    }

    const handleSearch = (event) => {
        setSearch("")
        setSearch(event.target.value)
    }

    let state = {
        data, setData,
        input, setInput,
        fetchStatus, setFetchStatus,
        currentId, setCurrentId,
        search, setSearch,
        tokenApi, setTokenApi
    }

    let handleFunction = {
        handleDelete,
        handleEdit,
        handleInput,
        handleSubmit,
        handleSearch,
        fetchData
    }

    return (
        <GlobalContext.Provider value={
            {
                state,
                handleFunction
            }
        }>
            {props.children}
        </GlobalContext.Provider>
    )
}