import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

let GantiPassword = () => {
    const [input, setInput] = useState({
        current_password: "",
        new_password: "",
        new_confirm_password: ""
    })

    const handleChange = (event) => {

        let value = event.target.value
        let name = event.target.name

        setInput({ ...input, [name]: value })
    }

    const handleLogin = (event) => {
        event.preventDefault()

        let { current_password, new_password, new_confirm_password } = input

        axios.post(`https://dev-example.sanbercloud.com/api/change-password`,
            { current_password, new_password, new_confirm_password },
            { headers: { "Authorization": "Bearer " + Cookies.get('token') } })
            .then((res) => {
                console.log(res.data)
                alert(res.data)
            }).catch((error) => {
                console.log(error.data)
                alert(error.data)
            })
    }

    return (
        <>
            <div className="p-4 sm:ml-64">
                <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14">
                <div className="relative overflow-x-auto mb-10 font-bold text-lg text-left">
                        <h1>Ganti Password</h1>
                    </div>
                    <form className="text-left" onSubmit={handleLogin}>
                        <div className="mb-6">
                            <label
                                htmlFor="current_password"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Current Password
                            </label>
                            <input
                                type="password"
                                id="current_password"
                                name="current_password"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="•••••••••"
                                required
                                onChange={handleChange}
                                value={input.current_password}
                            />
                        </div>
                        <div className="mb-6">
                            <label
                                htmlFor="new_password"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                New Password
                            </label>
                            <input
                                type="password"
                                id="new_password"
                                name="new_password"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="•••••••••"
                                required
                                onChange={handleChange}
                                value={input.new_password}
                            />
                        </div>
                        <div className="mb-6">
                            <label
                                htmlFor="new_confirm_password"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                New Confirm Password
                            </label>
                            <input
                                type="password"
                                id="new_confirm_password"
                                name="new_confirm_password"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="•••••••••"
                                required
                                onChange={handleChange}
                                value={input.new_confirm_password}
                            />
                        </div>
                        <button
                            type={'submit'}
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default GantiPassword