import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../contexts/globalContext";
import { useParams } from "react-router-dom";
import axios from "axios";

let Detail = () => {
    const { state } = useContext(GlobalContext)

    const urlApi = 'https://dev-example.sanbercloud.com/api/job-vacancy'
    const tokenApi = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvZGV2LWV4YW1wbGUuc2FuYmVyY2xvdWQuY29tXC9hcGlcL2xvZ2luIiwiaWF0IjoxNjg3NDkzNTE3LCJleHAiOjE2ODgwOTgzMTcsIm5iZiI6MTY4NzQ5MzUxNywianRpIjoiNDRRMjRFTVd2T2NPdGkyNiIsInN1YiI6MTM2MiwicHJ2IjoiODdlMGFmMWVmOWZkMTU4MTJmZGVjOTcxNTNhMTRlMGIwNDc1NDZhYSJ9.XBMSWxPvZq3C6q8Z869Bd7rgtRHdTnbmu9p0jUCVaQc'

    let { idData } = useParams()

    var bukaClass = 'bg-blue-500 w-20 py-1 font-normal text-center sm:rounded-lg text-gray-200 dark:text-gray-400'
    var tutupClass = 'bg-red-500 w-20 py-1 font-normal text-center sm:rounded-lg text-gray-200 dark:text-gray-400'

    const {
        input, setInput,
    } = state



    useEffect(() => {
        if (idData !== undefined) {
            axios.get(`${urlApi}/${idData}`,
                { headers: { "Authorization": "Bearer " + tokenApi } }
            )
                .then((res) => {
                    let data = res.data

                    setInput({
                        title: data.title,
                        job_description: data.job_description,
                        job_qualification: data.job_qualification,
                        job_type: data.job_type,
                        job_tenure: data.job_tenure,
                        job_status: data.job_status,
                        company_name: data.company_name,
                        company_image_url: data.company_image_url,
                        company_city: data.company_city,
                        salary_min: data.salary_min,
                        salary_max: data.salary_max,
                    })
                })
        }

    }, [setInput, idData])

    return (
        <>
            <>
                <section className="bg-center bg-no-repeat bg-blend-multiply">
                    <div className="px-4 mx-auto max-w-screen-xl text-left py-24">
                        <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-black md:text-5xl lg:text-6xl">
                            {input.title}
                        </h1>
                        <img className="py-10 rounded-t-lg max-w-sm min-w-sm" src={input.company_image_url} alt="" />
                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 line-clamp-3">
                            {input.job_description}
                        </p>
                        <h5 className="mb-2 text-lg font-bold tracking-tight text-black dark:text-white">
                            Criteria
                        </h5>
                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 line-clamp-3">
                            {input.job_qualification} | {input.job_type} | {input.job_tenure}
                        </p>
                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                            Rp. {input.salary_min},- s/d Rp. {input.salary_max},-
                        </p>
                        <h5 className="mb-2 text-lg font-bold tracking-tight text-black dark:text-gray-700">
                            Company
                        </h5>
                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                            {input.company_name} | {input.company_city}
                        </p>
                        <div className={input.job_status ? bukaClass : tutupClass}>
                            {input.job_status ? 'Buka' : 'Tutup'}
                        </div>
                    </div>
                </section>
            </>
        </>
    )
}

export default Detail