import React from "react";
import DashboardNav from "../components/dashboard-nav";

const DashboardLayout = (props) => {
    return (
        <>
            <DashboardNav/>
            {props.children}
        </>
    )
}

export default DashboardLayout