import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../contexts/globalContext";
import { useParams } from "react-router-dom";
import axios from "axios";

let DataForm = () => {
    const urlApi = 'https://dev-example.sanbercloud.com/api/job-vacancy'

    const tokenApi = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvZGV2LWV4YW1wbGUuc2FuYmVyY2xvdWQuY29tXC9hcGlcL2xvZ2luIiwiaWF0IjoxNjg3NDkzNTE3LCJleHAiOjE2ODgwOTgzMTcsIm5iZiI6MTY4NzQ5MzUxNywianRpIjoiNDRRMjRFTVd2T2NPdGkyNiIsInN1YiI6MTM2MiwicHJ2IjoiODdlMGFmMWVmOWZkMTU4MTJmZGVjOTcxNTNhMTRlMGIwNDc1NDZhYSJ9.XBMSWxPvZq3C6q8Z869Bd7rgtRHdTnbmu9p0jUCVaQc'
    
    const { state, handleFunction } = useContext(GlobalContext)

    let { variableIdData } = useParams()

    const {
        input, setInput,
    } = state

    const {
        handleInput,
        handleSubmit,
    } = handleFunction

    useEffect(() => {
        if (variableIdData !== undefined) {
            axios.get(`${urlApi}/${variableIdData}`,
                { headers: { "Authorization": "Bearer " + tokenApi } }
            )
                .then((res) => {
                    let data = res.data

                    setInput({
                        title: data.title,
                        job_description: data.job_description,
                        job_qualification: data.job_qualification,
                        job_type: data.job_type,
                        job_tenure: data.job_tenure,
                        job_status: data.job_status,
                        company_name: data.company_name,
                        company_image_url: data.company_image_url,
                        company_city: data.company_city,
                        salary_min: data.salary_min,
                        salary_max: data.salary_max,
                    })
                })
        }

    }, [setInput, variableIdData])

    return (
        <>
            <div className="p-4 sm:ml-64">
                <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14">
                    <div className="relative overflow-x-auto mb-10 font-bold text-lg text-left">
                        <h1>Form Data</h1>
                    </div>
                    <div className="relative overflow-x-auto my-10 text-left">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-6">
                                <label
                                    htmlFor="title"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Title:
                                </label>
                                <input
                                    type="text"
                                    id="title"
                                    name="title"
                                    required
                                    onChange={handleInput}
                                    value={input.title}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />
                            </div>
                            <div className="mb-6">
                                <label
                                    htmlFor="job_description"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Job Description:
                                </label>
                                <input
                                    type="text"
                                    id="job_description"
                                    name="job_description"
                                    required
                                    onChange={handleInput}
                                    value={input.job_description}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />
                            </div>
                            <div className="mb-6">
                                <label
                                    htmlFor="job_qualification"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Job Qualification:
                                </label>
                                <input
                                    type="text"
                                    id="job_qualification"
                                    name="job_qualification"
                                    required
                                    onChange={handleInput}
                                    value={input.job_qualification}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />
                            </div>
                            <div className="mb-6">
                                <label
                                    htmlFor="job_type"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Job Type:
                                </label>
                                <input
                                    type="text"
                                    id="job_type"
                                    name="job_type"
                                    required
                                    onChange={handleInput}
                                    value={input.job_type}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />
                            </div>
                            <div className="mb-6">
                                <label
                                    htmlFor="job_tenure"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Job Tenure:
                                </label>
                                <input
                                    type="text"
                                    id="job_tenure"
                                    name="job_tenure"
                                    required
                                    onChange={handleInput}
                                    value={input.job_tenure}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />
                            </div>
                            <div className="mb-6">
                                <label
                                    htmlFor="job_status"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Job Status:
                                </label>
                                <input
                                    type="number"
                                    id="job_status"
                                    name="job_status"
                                    required
                                    onChange={handleInput}
                                    value={input.job_status}
                                    max={1}
                                    min={0}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />
                            </div>
                            <div className="mb-6">
                                <label
                                    htmlFor="company_name"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Company Name:
                                </label>
                                <input
                                    type="text"
                                    id="company_name"
                                    name="company_name"
                                    required
                                    onChange={handleInput}
                                    value={input.company_name}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />
                            </div>
                            <div className="mb-6">
                                <label
                                    htmlFor="company_image_url"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Company Image Url:
                                </label>
                                <input
                                    type="text"
                                    id="company_image_url"
                                    name="company_image_url"
                                    required
                                    onChange={handleInput}
                                    value={input.company_image_url}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />
                            </div>
                            <div className="mb-6">
                                <label
                                    htmlFor="company_city"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Company City :
                                </label>
                                <input
                                    type="text"
                                    id="company_city"
                                    name="company_city"
                                    required
                                    onChange={handleInput}
                                    value={input.company_city}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />
                            </div>
                            <div className="mb-6">
                                <label
                                    htmlFor="salary_min"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Salary Min :
                                </label>
                                <input
                                    type="number"
                                    id="salary_min"
                                    name="salary_min"
                                    required
                                    onChange={handleInput}
                                    value={input.salary_min}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />
                            </div>
                            <div className="mb-6">
                                <label
                                    htmlFor="salary_max"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Salary Max :
                                </label>
                                <input
                                    type="number"
                                    id="salary_max"
                                    name="salary_max"
                                    required
                                    onChange={handleInput}
                                    value={input.salary_max}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />
                            </div>
                            <button
                                type={'submit'}
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DataForm