import React from "react";
import { Link, useNavigate } from "react-router-dom"
import { Navbar } from 'flowbite-react';
import Cookies from "js-cookie";

let CustomNavbar = () => {
    let navigate = useNavigate()
    const handleLogout = () => {
        Cookies.remove('token')
        navigate('/login')
    }

    return (
        <>
            <Navbar fluid rounded>
                <Navbar.Brand>
                    <Link to="/">
                        <span className="self-center text-lg font-bold whitespace-nowrap dark:text-white">
                            Final Project
                        </span>
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse>
                    <Link
                        to="/"
                    >
                        <Navbar.Link>
                            Beranda
                        </Navbar.Link>
                    </Link>
                    <Link
                        to="/about"
                    >
                        <Navbar.Link>
                            About
                        </Navbar.Link>
                    </Link>
                    {
                        !Cookies.get('token') &&
                        <>
                            <Link
                                to="/login"
                            >
                                <Navbar.Link>
                                    Log In
                                </Navbar.Link>
                            </Link>
                            <Link
                                to="/signin"
                            >
                                <Navbar.Link>
                                    Sign In
                                </Navbar.Link>
                            </Link>
                        </>
                    }
                    {
                        Cookies.get('token') &&
                        <>
                            <Link to={'/dashboard'}>
                                <Navbar.Link>
                                    Dashboard
                                </Navbar.Link>
                            </Link>
                            <Link onClick={handleLogout}>
                                <Navbar.Link>
                                    Log Out
                                </Navbar.Link>
                            </Link>
                        </>
                    }
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}

export default CustomNavbar

