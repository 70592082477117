import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../contexts/globalContext";
import Search from "../components/search";

let ListJobVacancy = () => {
    const { state, handleFunction } = useContext(GlobalContext)

    const {
        data,
        fetchStatus, setFetchStatus,
        search, setSearch
    } = state

    const {
        handleDelete,
        handleEdit,
        fetchData,
    } = handleFunction

    useEffect(() => {
        if (fetchStatus === true) {
            fetchData()
        }
    }, [fetchStatus, setFetchStatus, fetchData])

    return (
        <>
            <div className="p-4 sm:ml-64">
                <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14">
                    <div className="relative overflow-x-auto mb-10 font-bold text-lg text-left">
                        <h1>List Data</h1>
                    </div>
                    <div className="justify-center my-4 ">
                        <Search from={'list'} />
                    </div>
                    <div className="relative overflow-x-auto sm:rounded-lg shadow-lg">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr className='bg-blue-500 text-white'>
                                    <th scope="col" className="px-6 py-3">
                                        NO
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        TITLE
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        JOB DESCRIPTION
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        JOB QUALIFICATION
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        JOB TYPE
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        JOB TENURE
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        JOB STATUS
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        COMPANY NAME
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        COMPANY IMAGE URL
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        COMPANY CITY
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        SALARY MIN
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        SALARY MAX
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        ACTION
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data !== null && data.map((res, index) => {
                                    if (search == '') {
                                        return (
                                            <>
                                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                        {index += 1}
                                                    </th>
                                                    <td className="px-6 py-1 max-w-sm truncate">
                                                        {res.title}
                                                    </td>
                                                    <td className="px-6 py-1 max-w-sm truncate">
                                                        {res.job_description}
                                                    </td>
                                                    <td className="px-6 py-1 max-w-sm truncate">
                                                        {res.job_qualification}
                                                    </td>
                                                    <td className="px-6 py-1">
                                                        {res.job_type}
                                                    </td>
                                                    <td className="px-6 py-1">
                                                        {res.job_tenure}
                                                    </td>
                                                    <td className="px-6 py-1">
                                                        {res.job_status}
                                                    </td>
                                                    <td className="px-6 py-1">
                                                        {res.company_name}
                                                    </td>
                                                    <td className="px-6 py-1 max-w-sm truncate">
                                                        {res.company_image_url}
                                                    </td>
                                                    <td className="px-6 py-1">
                                                        {res.company_city}
                                                    </td>
                                                    <td className="px-6 py-1">
                                                        {res.salary_min}
                                                    </td>
                                                    <td className="px-6 py-1">
                                                        {res.salary_max}
                                                    </td>
                                                    <td className="px-6 py-1 flex">
                                                        <button onClick={handleEdit} value={res.id} type="button" className="text-gray-900 bg-yellow-200 border border-gray-400 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800">Edit</button>
                                                        <button onClick={handleDelete} value={res.id} type="button" className="text-white bg-red-600 border border-gray-400 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800">Delete</button>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    } else {
                                        if (res.title == search) {
                                            return (
                                                <>
                                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                            {index += 1}
                                                        </th>
                                                        <td className="px-6 py-1 max-w-sm truncate">
                                                            {res.title}
                                                        </td>
                                                        <td className="px-6 py-1 max-w-sm truncate">
                                                            {res.job_description}
                                                        </td>
                                                        <td className="px-6 py-1 max-w-sm truncate">
                                                            {res.job_qualification}
                                                        </td>
                                                        <td className="px-6 py-1">
                                                            {res.job_type}
                                                        </td>
                                                        <td className="px-6 py-1">
                                                            {res.job_tenure}
                                                        </td>
                                                        <td className="px-6 py-1">
                                                            {res.job_status}
                                                        </td>
                                                        <td className="px-6 py-1">
                                                            {res.company_name}
                                                        </td>
                                                        <td className="px-6 py-1 max-w-sm truncate">
                                                            {res.company_image_url}
                                                        </td>
                                                        <td className="px-6 py-1">
                                                            {res.company_city}
                                                        </td>
                                                        <td className="px-6 py-1">
                                                            {res.salary_min}
                                                        </td>
                                                        <td className="px-6 py-1">
                                                            {res.salary_max}
                                                        </td>
                                                        <td className="px-6 py-1 flex">
                                                            <button onClick={handleEdit} value={res.id} type="button" className="text-gray-900 bg-yellow-200 border border-gray-400 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800">Edit</button>
                                                            <button onClick={handleDelete} value={res.id} type="button" className="text-white bg-red-600 border border-gray-400 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800">Delete</button>
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        }
                                    }
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListJobVacancy