import React from "react"
import { useNavigate } from "react-router-dom";

let Card = (props) => {
    var bukaClass = 'bg-blue-500 w-20 py-1 font-normal text-center sm:rounded-lg text-gray-200 dark:text-gray-400'
    var tutupClass = 'bg-red-500 w-20 py-1 font-normal text-center sm:rounded-lg text-gray-200 dark:text-gray-400'

    let navigate = useNavigate()

    const handleClick = () => {
        let idData = props.id
        navigate(`/detail/${idData}`)
    }

    return (
        <div className="max-w-sm bg-gray-100 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 text-left">
            <div className="bg-white">
                <img className="p-10 rounded-t-lg max-h-50" src={props.company_image_url} alt="" />
            </div>
            <div className="p-5 bg-gray-100">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {props.title}
                </h5>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 line-clamp-3">
                    {props.job_description}
                </p>

                <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                    Criteria
                </h5>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 line-clamp-3">
                    {props.job_qualification} | {props.job_type} | {props.job_tenure}
                </p>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                    Rp. {props.salary_min},- s/d Rp. {props.salary_max},-
                </p>
                <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                    Company
                </h5>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                    {props.company_name} | {props.company_city}
                </p>
                <div className={props.job_status ? bukaClass : tutupClass}>
                    {props.job_status ? 'Buka' : 'Tutup'}
                </div>
                <button onClick={handleClick} className="inline-flex items-center my-3 px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Selengkapnya
                    <svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                </button>
            </div>
        </div>
    )
}

export default Card

